import * as React from "react";
import {
    Edit,
    ReferenceInput,
    SelectInput,
    NumberInput,
    required,
    DateInput,
    SimpleForm,
} from 'react-admin';
import {BeeSmartToolbar} from '../common/beesmart_toolbars';


const ErnteEdit = () => (
    <Edit mutationMode="pessimistic" >
        <SimpleForm  warnWhenUnsavedChanges toolbar={<BeeSmartToolbar/>}>
        <DateInput source="datum" validate={required()}/>
            <ReferenceInput source="stock" reference="stoecke">
                <SelectInput optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} validate={required()}/>
            </ReferenceInput >
            <NumberInput source="bluete" />
            <NumberInput source="sommer" />
            <NumberInput source="wald" />
            <NumberInput source="linde" />
            <NumberInput source="meli" />
            <NumberInput source="abschleudern" />
        </SimpleForm>
    </Edit>
);

export default ErnteEdit;