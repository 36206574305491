import { fetchUtils, DataProvider } from 'ra-core';

/**
 * Maps react-admin queries to a TreeQL powered REST API
 *
 * @see https://treeql.org
 *
 * @example
 *
 * getList          => GET http://my.api.url/records/posts?order=title,ASC&page=1,25
 * getOne           => GET http://my.api.url/records/posts/123
 * getMany          => GET http://my.api.url/records/posts?123,456,789
 * getManyReference => GET http://my.api.url/records/posts?filter=author_id,eq,345
 * update           => PUT http://my.api.url/records/posts/123
 * updateMany       => PUT http://my.api.url/records/posts/123,456,789
 * create           => POST http://my.api.url/records/posts/123
 * delete           => DELETE http://my.api.url/records/posts/123
 * deleteMany       => DELETE http://my.api.url/records/posts/123,456,789
 * 
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import treeqlProvider from 'ra-data-treeql';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={treeqlProvider('http://my.api.url/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const treeqlProvider = (apiUrl:string, httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: async (resource, params) => {
        
        let filter_query:string[] = [];
        if(params.pagination)
        {
            const { page, perPage } = params.pagination;
            filter_query.push(`page=${page},${perPage}`);
        }
        if(params.sort)
        {
            const { field, order } = params.sort;
            filter_query.push(`order=${field},${order}`);
        }
        
        let filter = {...fetchUtils.flattenObject(params.filter)};
        for (let key in filter) {
            if(key !== "q")
            {
                if(key.includes(','))
                {
                    filter_query.push(`filter=${key},${filter[key]}`);
                }
                else
                {
                    filter_query.push(`filter=${key},cs,${filter[key]}`);
                }
            }
            else
            {
                //not implemented                
            }
        }

        let url = `${apiUrl}/records/${resource}?${filter_query.join("&")}`;

        if(resource.includes('?join'))
        {
            url = `${apiUrl}/records/${resource}&${filter_query.join("&")}`;
        }

        const { json } = await httpClient(url);
        return ({
            data: json.records,
            total: json.results,
        });
    },

    getOne: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/records/${resource}/${params.id}`);
        return ({
            data: json,
        });
    },

    getMany: async (resource, params) => {
        const url = `${apiUrl}/records/${resource}/${params.ids.join(',')}`;
        const { json } = await httpClient(url);
        return ({ data: Array.isArray(json) ? json : [json] });
    },

    // TODO: filter is not well-formed
    getManyReference: async (resource, params) => {     
        let filter_query:string[] = [];
        if(params.pagination)
        {
            const { page, perPage } = params.pagination;
            filter_query.push(`page=${page},${perPage}`);
        }
        if(params.sort)
        {
            const { field, order } = params.sort;
            filter_query.push(`order=${field},${order}`);
        }        
        let filter = {...fetchUtils.flattenObject(params.filter)};
        for (let key in filter) {
            if(key !== "q")
            {
                if(key.includes(','))
                {
                    filter_query.push(`filter=${key},${filter[key]}`);
                }
                else
                {
                    filter_query.push(`filter=${key},cs,${filter[key]}`);
                }
            }
            else
            {
                //not implemented                
            }
        }
        filter_query.push("filter="+params.target+",eq,"+params.id);
        
        const url = `${apiUrl}/records/${resource}?${filter_query.join("&")}`;

        const { json } = await httpClient(url);
        return ({
            data: json.records,
            total: json.results,
        });
    },

    // TODO: check status
    update: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/records/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        });
        return ({ data: json });
    },

    // TODO: check status
    updateMany: async (resource, params) => {
        const updateArray:any [] = new Array(params.ids.length)
        for(let i = 0;i<updateArray.length;i++) { 
            updateArray[i] = params.data;
         }
        const { json } = await httpClient(`${apiUrl}/records/${resource}/${params.ids.join(',')}`, {
            method: 'PUT',
            body: JSON.stringify(updateArray),
        });
        return ({ status: json, data: updateArray });

    },

    create: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/records/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        });
        return ({
            data: { ...params.data, id: json },
        });
    },

    // TODO: check status
    delete: async (resource, params) => {
        const { json } =  await httpClient(`${apiUrl}/records/${resource}/${params.id}`, {
            method: 'DELETE',
        });
        return ({ data: json, id: params.id });
    },

    // TODO: check status
    deleteMany: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/records/${resource}/${params.ids.join(',')}`, {
            method: 'DELETE',
        });
        return ({ status: json, data: params.ids });
    },
});

export default treeqlProvider;