import * as React from "react";
import {
    RaRecord,
    useRecordContext,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';


export const sumTotalErnte = (record: RaRecord):number =>{
   return (record.bluete + record.sommer + record.wald + record.linde + record.meli + record.abschleudern)
}

export const TotalErnteField = () => {
    const record = useRecordContext();
    const total_ernte = sumTotalErnte(record);
    return (
        <Typography
            variant="body1"
            component="span"
            className="total_ernte"
            >
            {new Intl.NumberFormat('de-DE').format(total_ernte)}
        </Typography>
    )
};

TotalErnteField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};