import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ErnteCreate from './ErnteCreate';
import ErnteEdit from './ErnteEdit';
import ErnteList from './ErnteList';
import ErnteShow from './ErnteShow';


const ernte_ressource = {
    list: ErnteList,
    show: ErnteShow,
    edit: ErnteEdit,
    create: ErnteCreate,
    icon: LocalDrinkIcon,
};

export default ernte_ressource;