import EuroIcon from '@mui/icons-material/Euro';
import ZahlungenCreate from './ZahlungenCreate';
import ZahlungenEdit from './ZahlungenEdit';
import ZahlungenList from './ZahlungenList';
import ZahlungenShow from './ZahlungenShow';


const zahlungen_ressource = {
    list: ZahlungenList,
    show: ZahlungenShow,
    edit: ZahlungenEdit,
    create: ZahlungenCreate,
    icon: EuroIcon,
};

export default zahlungen_ressource;