import * as React from "react";
import {
    List,
    Datagrid,
    FunctionField,
    ReferenceField,
    DateField,
    SimpleList,
    RaRecord,
    downloadCSV,
} from 'react-admin';
import {FilterAside, FilterSmall} from '../common/beesmart_filters';
import {useMediaQuery, Theme } from '@mui/material';
import {TotalErnteField} from './TotalErnteField';
import jsonExport from 'jsonexport/dist';

const exporter = (records:RaRecord[], fetchRelatedRecords:any) =>
    fetchRelatedRecords(records, 'stock', 'stoecke').then((posts: { [i: number]: { name: string; ort: string; platz:string }; }) => {
        const data = records.map(record => {
            const { stock, ...recordForExport } = record; // omit stock
            recordForExport.stock = posts[record.stock].name;
            recordForExport.ort = posts[record.stock].ort;
            recordForExport.platz = posts[record.stock].platz;
            return recordForExport;
        });

        jsonExport(data, (error, csv) => {
            if (error) {
                console.error(error);
            }
            downloadCSV(csv, 'ernten');
        });
    });

const ErnteList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            aside={<FilterAside />}
            perPage={25}
            sort={{ field: 'datum', order: 'DESC' }}
            filters={isSmall ? FilterSmall : undefined}
            exporter={exporter}
        >
            {isSmall ? (
                <SimpleList linkType="show"                     
                    primaryText={record =>
                    (
                        <ReferenceField record={record} label="Volk" source="stock" reference="stoecke" link={false}>
                            <FunctionField
                                label="Stock"
                                render={(record:any) => `${record.name} (${record.ort} | ${record.platz})`}
                            />
                        </ReferenceField>
                    )}
                    secondaryText={record => <TotalErnteField record={record} />}
                    tertiaryText={record => new Intl.DateTimeFormat("de",{dateStyle: "short"}).format(new Date(record.datum))}
                />
            ) : (
                <Datagrid rowClick="show">
                    <DateField source="datum" />
                    <ReferenceField label="Stock" source="stock" reference="stoecke" link={false}>
                        <FunctionField
                            label="Stock"
                            render={(record:any) => `${record.name} (${record.ort} | ${record.platz})`}
                        />
                    </ReferenceField>
                    <TotalErnteField label="Gesamt"/>
                </Datagrid>
            )}
        </List>
    );
};

export default ErnteList;