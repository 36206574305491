import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Filter,
    SearchInput,
    SimpleList,
    BooleanField,
} from 'react-admin';
import {Chip, useMediaQuery, Theme } from '@mui/material';


const QuickFilter = ({ ...props }: { label: string, source: string, defaultValue: any }) => {
    return <Chip label={(props.label)} />;
};

const StockFilter = () => (
    <Filter>
        <SearchInput source="name" alwaysOn />
        <SearchInput source="ort" />
        <QuickFilter label="Active" source="aktiv" defaultValue={1} />
        <QuickFilter label="Zucht-Königin" source="zucht_koenigin" defaultValue={1} />
    </Filter>
);

const StockList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={<StockFilter />}
        >
            {isSmall ? (
                <SimpleList 
                    primaryText={record => record.name}
                    secondaryText={record => (record.ort + " | " + record.platz)}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <TextField source="ort" />
                    <NumberField source="platz" />
                    <BooleanField source="zucht_koenigin" />
                    <BooleanField source="aktiv" />
                </Datagrid>
            )}
        </List>
    )
};

export default StockList;