import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SimpleList,
    DateInput,
    NumberField,
} from 'react-admin';
import {useMediaQuery, Theme } from '@mui/material';

const ZahlungenListFilter = [
    <DateInput source="datum,gt" label="nach" alwaysOn />,
    <DateInput source="datum,lt" label="vor" alwaysOn />
];

const ZahlungenList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List
            perPage={25}
            sort={{ field: 'datum', order: 'DESC' }}
            filters={ZahlungenListFilter}
        >
            {isSmall ? (
                <SimpleList linkType="show"    
                    primaryText={record => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(record.betrag)}                 
                    secondaryText={record => record.art}
                    tertiaryText={record => new Intl.DateTimeFormat("de",{dateStyle: "short"}).format(new Date(record.datum))}
                />
            ) : (
                <Datagrid rowClick="show">
                    <DateField source="datum" />
                    <TextField source="art" />
                    <NumberField source="betrag" />
                </Datagrid>
            )}
        </List>
    );
};

export default ZahlungenList;