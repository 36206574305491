import * as React from "react";
import {
    Create,
    DateInput,
    NumberInput,
    SimpleForm,
    required,
    SelectInput,
    useGetList,
    useResourceContext,
} from 'react-admin';


const ZahlungenCreate = () => {
    const resource = useResourceContext();
    const { data: art_data, isLoading } = useGetList(
        String(resource),
        {  },
    ); 
    if (isLoading) return null;
    let uniqueArt:any[] = [];
    if(art_data)
    {
        art_data.forEach((elem:any) => {
            if (!uniqueArt.some((uniq_elem) => (uniq_elem.name === elem.art))) {
                uniqueArt.push({"id":elem.art, "name": elem.art});
            }
        });
    }

    return (
        <Create>
            <SimpleForm redirect="list" warnWhenUnsavedChanges>
                <DateInput source="datum" validate={required()} defaultValue={new Date().toISOString().split('T', 1)[0]} />
                <SelectInput
                    onCreate={() => {
                        const newArtName = prompt('Füge neuen Eintrag hinzu:');
                        if(newArtName ===  null) return uniqueArt[0];
                        const newArt = { id: newArtName, name: newArtName };
                        uniqueArt.push(newArt);
                        return newArt;
                    }}
                    source="art"
                    choices={uniqueArt}
                />
                <NumberInput source="betrag" validate={required()} />
            </SimpleForm>
        </Create>
    );
}
export default ZahlungenCreate;