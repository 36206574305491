import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    DateField,
    SelectField,
    SelectInput,
    SimpleList,
} from 'react-admin';
import NoteEdit from "./noteEdit";
import {useMediaQuery, Theme } from '@mui/material';



const NoteFilter = [
    <SearchInput source="summary" alwaysOn />,
    <SelectInput source="status" choices={[
        {id:'open', name:'Offen'}, 
        {id:'in progress', name:'in Bearbeitung'}, 
        {id:'done', name:'erledigt'},
        {id:'information', name:'Information'}]} />,
];

const NoteList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={NoteFilter}>
            {isSmall ? (
                <SimpleList linkType="edit"
                    primaryText={record => record.summary}
                    secondaryText={record => record.status}
                    tertiaryText={record => new Intl.DateTimeFormat("de", { dateStyle: "short" }).format(new Date(record.due_date))}
                />
            ) : (
                <Datagrid expand={<NoteEdit />}>
                    <DateField source="due_date" label="Due-Date" />
                    <TextField source="summary" label="Titel" />
                    <SelectField source="status" choices={[
                        { id: 'open', name: 'Offen' },
                        { id: 'in progress', name: 'in Bearbeitung' },
                        { id: 'done', name: 'erledigt' },
                        { id: 'information', name: 'Information' }]} />
                    <DateField source="creation_date" label="Creation-Date" />
                </Datagrid>
            )}
    </List>
    )
}

export default NoteList;