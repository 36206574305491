import * as React from "react";
import {
    DateInput,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {
    required,
    maxLength,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';


const NoteCreate = () => (
    <Create >
        <SimpleForm >
            <TextInput source="summary" label="Titel" validate={[required(), maxLength(250)]} />
            <DateInput source="due_date" label="Due-Date" />
            <SelectInput source="status" validate={required()} choices={[
                { id: 'open', name: 'Offen' },
                { id: 'in progress', name: 'in Bearbeitung' },
                { id: 'done', name: 'erledigt' },
                { id: 'information', name: 'Information' }]} />
            <RichTextInput source="details" />
        </SimpleForm>
    </Create>
);

export default NoteCreate;