import * as React from "react";
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
import { parseISO, startOfYear, getMonth } from 'date-fns';
import { Title, useGetList, RaRecord } from 'react-admin'

const VerticalSpacer = () => <span style={{ height: '1em', display: 'block' }} />;

const sumZahlungen = (zahlungen: RaRecord[]): number => {
    let total: number = 0;
    zahlungen.forEach(curr => {
        total += curr.betrag;
    });
    return total;
};

const aggregateZahlungenByMonth = (zahlungen: RaRecord[]): { [key: number]: number } =>
    zahlungen.reduce((acc, curr) => {
        const month = getMonth(parseISO(curr.datum));
        if (!acc[month]) {
            acc[month] = 0;
        }
        acc[month] += Number(curr.betrag);
        return acc;
    }, {} as { [key: number]: number });

const getGewinnVerlustByMonth = (all_einnahmen: RaRecord[], all_ausgaben: RaRecord[]): { month: string, einnahmen: number, ausgaben: number }[] => {
    const monthWithEinnahmen = aggregateZahlungenByMonth(all_einnahmen);
    const monthWithAusgaben = aggregateZahlungenByMonth(all_ausgaben);
    const month_names = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    let result: { month: string, einnahmen: number, ausgaben: number }[] = [];
    let acc_einnahmen: number = 0;
    let acc_ausgaben: number = 0;
    month_names.forEach((month_name, index) => {
        if (monthWithEinnahmen[index])
            acc_einnahmen += Number(monthWithEinnahmen[index]);
        if (monthWithAusgaben[index])
            acc_ausgaben += Number(monthWithAusgaben[index]);
        result.push({
            month: month_name,
            einnahmen: acc_einnahmen,
            ausgaben: acc_ausgaben,
        })
    });
    return result;
};

const Bilanz = () => {
    const { data: einnahmen, isLoading: ein_loading } = useGetList(
        'einnahmen',
        {
            filter: { "datum,gt": startOfYear(new Date()).toISOString() },
            sort: { field: 'datum', order: 'DESC' },
        },
    );
    const { data: ausgaben, isLoading: aus_loading } = useGetList(
        'ausgaben',
        {
            filter: { "datum,gt": startOfYear(new Date()).toISOString() },
            sort: { field: 'datum', order: 'DESC' },
        },
    );
    if (ein_loading || aus_loading) return null
    return (
        <Box m={1}>
            <Card>
                <Title title="Bilanz" />
                <CardContent>
                    <Typography gutterBottom={true} >
                        Gesamtübersicht Einnahmen und Ausgaben des laufenden Jahres:
                </Typography>
                    <VerticalSpacer />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
                                Ausgaben: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sumZahlungen(ausgaben as RaRecord[]))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
                                Einnahmen: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sumZahlungen(einnahmen as RaRecord[]))}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
                <VerticalSpacer />
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={getGewinnVerlustByMonth(einnahmen as RaRecord[], ausgaben as RaRecord[])}>
                            <XAxis
                                dataKey="month"
                            />
                            <YAxis name="Betrag" />
                            <Tooltip />
                            <Area type="monotone" dataKey="ausgaben" stroke="#795548" fill="#795548" />
                            <Area type="monotone" dataKey="einnahmen" stroke="#f9c02d" fill="#f9c02d" />
                            <CartesianGrid strokeDasharray="3 3" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </Box>
    )
};

export default Bilanz;