import * as React from 'react';
import { FC } from 'react';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import TimeIcon from '@mui/icons-material/AccessTime';
import { Card, CardContent} from '@mui/material';
import {
    FilterList,
    FilterListItem,
    ReferenceInput,
    SelectInput,
    useGetList,
} from 'react-admin';
import {
    startOfMonth,
    subMonths,
    startOfYear,
    subYears,
} from 'date-fns';

const FilterAside: FC = () => {
    const { data: stoecke_data, isLoading } = useGetList(
        'stoecke',
        {}
        );

    if (isLoading) return null;
    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent>
                <FilterList
                    label="Bienenstöcke"
                    icon={<EmojiNatureIcon />}
                >
                    {stoecke_data && stoecke_data.map((data: any) => (
                        <FilterListItem
                            label={(data.name+" ("+data.ort+" | "+data.platz+")")}
                            key={data.id}
                            value={{ "stock,eq": data.id }}
                        />
                    ))}
                </FilterList>
                <FilterList label="Datum" icon={<TimeIcon />}>
                    <FilterListItem
                        label="Diesen Monat"
                        value={{
                            "datum,gt": startOfMonth(new Date()).toISOString(),
                        }}
                    />
                    <FilterListItem
                        label="Letzten Monat"
                        value={{
                            "datum,gt": subMonths(startOfMonth(new Date()), 1).toISOString(),
                            "datum,lt": startOfMonth(new Date()).toISOString(),
                        }}
                    />
                    <FilterListItem
                        label="Dieses Jahr"
                        value={{
                            "datum,gt": startOfYear(new Date()).toISOString(),
                        }}
                    />
                    <FilterListItem
                        label="Letztes Jahr"
                        value={{
                            "datum,gt": subYears(startOfYear(new Date()), 1).toISOString(),
                            "datum,lt": startOfYear(new Date()).toISOString(),
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

const FilterSmall = [
    <ReferenceInput label="Stock" source="stock" reference="stoecke">
        <SelectInput source="name" optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} />
    </ReferenceInput >,
];

export {FilterAside, FilterSmall};