import FastfoodIcon from '@mui/icons-material/Fastfood';
import FuetterungCreate from './FuetterungCreate';
import FuetterungEdit from './FuetterungEdit';
import FuetterungList from './FuetterungList';
import FuetterungShow from './FuetterungShow';


const fuetterung_ressource = {
    list: FuetterungList,
    show: FuetterungShow,
    edit: FuetterungEdit,
    create: FuetterungCreate,
    icon: FastfoodIcon,
};

export default fuetterung_ressource;