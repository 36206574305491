import * as React from "react";
import {
    Edit,
    NumberInput,
    required,
    DateInput,
    SimpleForm,
    useGetList,
    SelectInput,
    useResourceContext,
} from 'react-admin';
import {BeeSmartToolbar} from '../common/beesmart_toolbars';

const ZahlungenEdit = () => {
    const resource = useResourceContext();
    const { data: art_data, isLoading } = useGetList(
        String(resource),
        {},
    );
    if (isLoading) return null;
    let uniqueArt: any[] = [];
    if(art_data)
    {
        art_data.forEach((elem: any) => {
            if (!uniqueArt.some((uniq_elem) => (uniq_elem.name === elem.art))) {
                uniqueArt.push({ "id": elem.art, "name": elem.art });
            }
        });
    }
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm  warnWhenUnsavedChanges toolbar={<BeeSmartToolbar/>}>
                <DateInput source="datum" validate={required()} />
                <SelectInput
                    onCreate={() => {
                        const newArtName = prompt('Füge neuen Eintrag hinzu:');
                        if(newArtName ===  null) return uniqueArt[0];
                        const newArt = { id: newArtName, name: newArtName };
                        uniqueArt.push(newArt);
                        return newArt;
                    }}
                    source="art"
                    choices={uniqueArt}
                />
                <NumberInput source="betrag" />
            </SimpleForm>
        </Edit>
    )
};

export default ZahlungenEdit;