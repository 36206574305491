import * as React from "react";
import {
    Edit,
    ReferenceInput,
    SelectInput,
    NumberInput,
    required,
    DateInput,
    TabbedForm,
    FormTab,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';
import {staerke} from '../common/beesmart_enums'
import {BeeSmartToolbar} from '../common/beesmart_toolbars';


const DurchsichtEdit = () => (
    <Edit >
        <TabbedForm mutationMode="pessimistic" warnWhenUnsavedChanges toolbar={<BeeSmartToolbar/>}>
            <FormTab label="Generell">
                <DateInput source="datum" validate={required()} />
                <ReferenceInput source="stock" reference="stoecke">
                    <SelectInput optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} validate={required()} />
                </ReferenceInput >
                <SelectInput source="staerke" choices={staerke} />
                <SelectInput source="schwarmtrieb" choices={staerke} />
            </FormTab>
            <FormTab label="Gewicht">
                <NumberInput source="gewicht" />
                <NumberInput source="brutraum" />
                <NumberInput source="honigraum" />
            </FormTab>
            <FormTab label="Details">
                <NumberInput source="befall" />
                <RichTextInput source="notiz" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default DurchsichtEdit;