import EmojiNatureIcon from '@mui/icons-material/EmojiNature';

import StockList from './StockList';
import StockEdit from './StockEdit';
import StockCreate from './StockCreate';
import StockShow from './StockShow';

const stock_ressource = {
    list: StockList,
    show: StockShow,
    edit: StockEdit,
    create: StockCreate,
    icon: EmojiNatureIcon,
};

export default stock_ressource;