
import { createElement } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import LabelIcon from '@mui/icons-material/Label';

const BSMenu = (props:any) => {
    const resources = useResourceDefinitions()
    const [open] = useSidebarState();
    return (
        <Menu>
            <DashboardMenuItem />
            {Object.values(resources).filter(r => r.hasList).map(res => (
                <MenuItemLink {...props}
                    key={res.name}
                    to={`/${res.name}`}
                    primaryText={
                        (resources[res.name].options && resources[res.name].options.label) ||
                        res.name
                    }
                    leftIcon={
                        resources[res.name].icon ? createElement(resources[res.name].icon) : <DefaultIcon />
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {/* @ts-ignore */}
            <MenuItemLink
                to="/bilanz"
                primaryText="Bilanz"
                leftIcon={<LabelIcon />}
            />
        </Menu>
    );
};

export default BSMenu;
