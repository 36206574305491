import * as React from "react";
import { 
    Edit, 
    SimpleForm, 
    TextInput,
    NumberInput, 
    BooleanInput, 
    required,
    maxLength,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';
import {BeeSmartToolbar} from '../common/beesmart_toolbars';

const StockEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm warnWhenUnsavedChanges toolbar={<BeeSmartToolbar/>}>
            <TextInput source="name" validate={[required(),maxLength(250)]}/>
            <TextInput source="ort" validate={[maxLength(250)]}/>
            <NumberInput source="platz" />
            <BooleanInput source="zucht_koenigin" />
            <BooleanInput source="aktiv" />
            <RichTextInput source="notiz" />
        </SimpleForm>
    </Edit>
);

export default StockEdit;