import * as React from "react";
import { Route } from "react-router-dom";
import { Admin, Login, Resource, CustomRoutes, defaultTheme } from 'react-admin';
import basicAuthProvider from './authProvider';
import treeqlProvider from './dataProvider';
import Dashboard from './dashboard';
import stoecke from './stoecke';
import durchsichten from './durchsicht';
import behandlungen from './behandlung';
import ernten from './ernte';
import fuetterung from './fuetterung';
import zahlungen from './zahlungen';
import notes from './notes';
import BsLayout from './BsLayout';
import Bilanz from './bilanz/Bilanz';

const myTheme = {
  ...defaultTheme,
  palette: {
    secondary: {
      light: '#fff263',
      main: '#f9c02d',
      dark: '#c29000',
      contrastText: '#424242',
    },
    primary: {
      light: '#a98274',
      main: '#795548',
      dark: '#4b2c20',
      contrastText: '#fff',
    },
  },
};


const MyLoginPage = () => {
  return(
  <Login
   sx={{background: 'radial-gradient(circle at 50% 14em, rgba(50, 50, 50, 0.8), rgba(80, 80, 80, 0.2)), url(bg.png), repeat' , backgroundSize: 'auto'}}
  />
);
  }
const dataProvider = treeqlProvider('/api.php');
const authProvider = basicAuthProvider();
const App = () => (
    <Admin theme={myTheme} layout={BsLayout} dashboard={Dashboard} loginPage={MyLoginPage} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name="notes" options={{ label: 'Notizen' }} {...notes} />
        <Resource name="stoecke" options={{ label: 'Bienenstöcke' }} {...stoecke} />
        <Resource name="durchsichten" options={{ label: 'Durchsichten' }} {...durchsichten}/>
        <Resource name="behandlungen" options={{ label: 'Behandlungen' }} {...behandlungen}/>
        <Resource name="fuetterungen" options={{ label: 'Fütterungen' }} {...fuetterung}/>
        <Resource name="ernten" options={{ label: 'Ernten' }} {...ernten}/>
        <Resource name="einnahmen" options={{ label: 'Einnahmen' }} {...zahlungen}/>
        <Resource name="ausgaben" options={{ label: 'Ausgaben' }} {...zahlungen}/>
        <Resource name="users"/>
        <CustomRoutes>
          <Route path="/bilanz" element={<Bilanz />} />
        </CustomRoutes>
    </Admin>
);

export default App;