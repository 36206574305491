import InfoIcon from '@mui/icons-material/Info';
import DurchsichtCreate from './DurchsichtCreate';
import DurchsichtEdit from './DurchsichtEdit';
import DurchsichtList from './DurchsichtList';
import DurchsichtShow from './DurchsichtShow';


const durchsicht_resource = {
    list: DurchsichtList,
    show: DurchsichtShow,
    edit: DurchsichtEdit,
    create: DurchsichtCreate,
    icon: InfoIcon,
};

export default durchsicht_resource;