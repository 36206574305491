import * as React from "react";
import { 
    Create, 
    SimpleForm, 
    TextInput, 
    required,
    maxLength,
    BooleanInput,
    NumberInput,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';

const StockCreate = () => (
    <Create>
        <SimpleForm redirect="list" warnWhenUnsavedChanges>
            <TextInput source="name" validate={[required(),maxLength(250)]}/>
            <TextInput source="ort" validate={[maxLength(250)]}/>
            <NumberInput source="platz" />
            <BooleanInput source="zucht_koenigin" />
            <BooleanInput source="aktiv" />
            <RichTextInput source="notiz" />
        </SimpleForm>
    </Create>
);

export default StockCreate;