import * as React from "react";
import {
    Edit,
    ReferenceInput,
    SelectInput,
    NumberInput,
    required,
    DateInput,
    SimpleForm,
} from 'react-admin';
import {BeeSmartToolbar} from '../common/beesmart_toolbars';


const FuetterungEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm warnWhenUnsavedChanges toolbar={<BeeSmartToolbar/>}>
            <DateInput source="datum" validate={required()} />
            <ReferenceInput source="stock" reference="stoecke">
                <SelectInput optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} validate={required()}/>
            </ReferenceInput >

            <NumberInput source="menge" />
        </SimpleForm>
    </Edit>
);

export default FuetterungEdit;