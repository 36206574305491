import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    BooleanField,
    RichTextField,
    Tab,
    ReferenceManyField,
    Datagrid,
    DateField,
    SelectField,
    TabbedShowLayout,
} from 'react-admin';
import { staerke, behandlungsart } from '../common/beesmart_enums'
import IconButton from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { BeeSmartShowActions } from '../common/beesmart_toolbars';
import { TotalErnteField } from '../ernte/TotalErnteField';
import { SumOfColumnField, FieldAreaChart, FieldLineChart } from './StockInfo';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DurchsichtIcon from '@mui/icons-material/Info';
import FuetterungIcon from '@mui/icons-material/Fastfood';
import BehandlungenIcon from '@mui/icons-material/Healing';
import ErntenIcon from '@mui/icons-material/LocalDrink';
import { useParams } from 'react-router-dom';

const VerticalDivider = () => <><span style={{ height: '2em', display: 'block' }} /> <Divider/> <span style={{ height: '2em', display: 'block' }} /> </>;
const VerticalSpacer = () => <span style={{ height: '2em', display: 'block' }} />;

const CreateRelatedButton = ({ id, reference }:{id:any, reference:string}) => (
    <IconButton
        component={Link}
        to={{
            pathname: '/' + reference + '/create',
            //state: { record: { stock: Number(id) } },
            search: `?source=${JSON.stringify({ stock: id })}`,
        }}
        aria-label="add new entry"
        variant="outlined"
    >
        <AddIcon />
    Add Entry
    </IconButton>
);

const ClientShow = () => {
    const { id } = useParams();
    return (
        <Show actions={<BeeSmartShowActions />}>
            <>
                <SimpleShowLayout>
                    <TextField source="name" />
                    <TextField source="ort" />
                    <NumberField source="platz" />
                    <BooleanField source="zucht_koenigin" />
                    <BooleanField source="aktiv" />
                    <RichTextField source="notiz" />
                </SimpleShowLayout>
                <VerticalSpacer/>
                <TabbedShowLayout className="fullWidth">
                    <Tab icon={<DurchsichtIcon/>} label="" >
                        <Box style={{ width: '100%', textAlign: 'right' }} >
                            <ReferenceManyField reference="durchsichten" target="stock" label="" sort={{ field: 'datum', order: 'DESC' }}>
                                <Datagrid rowClick="show">
                                    <DateField source="datum" />
                                    <SelectField source="staerke" choices={staerke} />
                                    <NumberField source="gewicht" />
                                    <RichTextField source="notiz" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Box>
                        <VerticalSpacer/>
                        <CreateRelatedButton id={id} reference="durchsichten" />
                        <VerticalDivider/>
                        <FieldLineChart reference="durchsichten" stock={id} fields={["gewicht"]} label="Gewicht"/>
                        <VerticalDivider/>
                        <FieldLineChart reference="durchsichten" stock={id} fields={["befall"]} label="Befall"/>
                    </Tab>
                    <Tab icon={<BehandlungenIcon/>} label="">
                        <Box style={{ width: '100%', textAlign: 'right' }} >
                            <ReferenceManyField reference="behandlungen" target="stock" label="" sort={{ field: 'datum', order: 'DESC' }}>
                                <Datagrid rowClick="show">
                                    <DateField source="datum" />
                                    <SelectField source="art" choices={behandlungsart} />
                                    <NumberField source="menge" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Box>
                        <VerticalSpacer/>
                        <CreateRelatedButton id={id} reference="behandlungen" />
                    </Tab>
                    <Tab icon={<FuetterungIcon/>} label="">
                        <Box style={{ width: '100%', textAlign: 'right' }} >
                            <ReferenceManyField reference="fuetterungen" target="stock" label="" sort={{ field: 'datum', order: 'DESC' }}>
                                <Datagrid rowClick="show">
                                    <DateField source="datum" />
                                    <NumberField source="menge" />
                                </Datagrid>
                            </ReferenceManyField>
                            <SumOfColumnField reference="fuetterungen" stock={id} field="menge" label="Gesamt" />
                        </Box>
                        <CreateRelatedButton id={id} reference="fuetterungen" />
                        <VerticalDivider/>
                        <FieldAreaChart reference="fuetterungen" stock={id} fields={["menge"]} label="Fütterungen"/>
                    </Tab>

                    <Tab icon={<ErntenIcon/>} label="">
                        <Box style={{ width: '100%', textAlign: 'right' }} >
                            <ReferenceManyField reference="ernten" target="stock" label="" sort={{ field: 'datum', order: 'DESC' }}>
                                <Datagrid rowClick="show" >
                                    <DateField source="datum" />
                                    <TotalErnteField label="Gesamt" />
                                </Datagrid>
                            </ReferenceManyField>
                            <SumOfColumnField reference="ernten" stock={id} fields={["bluete", "sommer", "wald", "linde", "meli", "abschleudern"]} label="Gesamt" />
                        </Box>
                        <CreateRelatedButton id={id} reference="ernten" />
                        <VerticalDivider/>
                        <FieldAreaChart reference="ernten" stock={id} fields={["bluete", "sommer", "wald", "linde", "meli", "abschleudern"]} label="Ernte"/>
                    </Tab>
                </TabbedShowLayout>
                <VerticalSpacer/>
            </>
        </Show>
    );
};

export default ClientShow;