import React from 'react';
import {
    Toolbar,
    SaveButton,
    DeleteButton,
    TopToolbar,
    EditButton,
    CloneButton,
    ListButton,
    EditActionsProps,
} from 'react-admin';


const BeeSmartToolbar = () => (
    <Toolbar>
        <SaveButton/>
        <DeleteButton mutationMode='undoable' />
    </Toolbar>
);


const BeeSmartShowActions = ({data }: EditActionsProps) => (
    <TopToolbar >
        <ListButton />
        <CloneButton record={data} />
        <EditButton record={data} />
    </TopToolbar>
);

export {BeeSmartToolbar, BeeSmartShowActions};