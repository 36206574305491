import * as React from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import { useGetList, RaRecord } from 'react-admin';
import { Link } from 'react-router-dom';
import CardWithIcon from './CardWithIcon';

const LetzteDurchsicht = (stock:any) => {
    const { data, isLoading, error } = useGetList(
        'durchsichten',
        {
            filter: {
                "stock,eq": stock.stock,
            },
            sort: { field: 'datum', order: 'Desc' },
            pagination: { page: 1, perPage: 1 },
        },
    );
    if (isLoading || error) return null;
    let last_durchsicht:string = "Keine Durchsicht!";
    if (data && data.length > 0)
    {
        last_durchsicht = new Intl.DateTimeFormat("de", { dateStyle: "short" }).format(new Date(data[0].datum));
    }
    return (
        <ListItemText  secondary={last_durchsicht}  />
    )
}

const StoeckeInOrt = ({inOrt, label}:{inOrt:string, label:string}) => {


    const { data: stoecke, isLoading } = useGetList(
        'stoecke',
        {
            filter: {
                ort: inOrt,
                aktiv: 1,
            },
            sort: { field: 'platz', order: 'ASC' },
            pagination: { page: 1, perPage: 30 },
        },
    );

    if (isLoading) return null;
    const nb = stoecke ? stoecke.reduce((nb: number) => ++nb, 0) : 0;

    return (
        <CardWithIcon
            to={`/stoecke?filter={"ort":"${inOrt}"}`}
            icon={EmojiNatureIcon}
            title={label}
            subtitle={nb}
        >
            <List>
                {stoecke
                    ? stoecke.map((record: RaRecord) => (
                          <ListItem
                              button
                              to={`/stoecke/${record.id}/show`}
                              component={Link}
                              key={record.id}
                          >
                              <ListItemText
                                  primary={record.name}
                                  secondary={"Platz: "+record.platz}
                                  
                              />
                              <LetzteDurchsicht stock={record.id}/> 
                          </ListItem>
                      ))
                    : null}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
        </CardWithIcon>
    );
};


export default StoeckeInOrt;