import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    ChipField,
    NumberField,
    SelectField,
    DateField,
} from 'react-admin';
import {behandlungsart} from '../common/beesmart_enums'
import {BeeSmartShowActions} from '../common/beesmart_toolbars';

const BehandlungShow = () => (
    <Show
        actions={<BeeSmartShowActions/>}
    >
        <SimpleShowLayout>
            <DateField source="datum" />
            <ReferenceField source="stock" reference="stoecke">
                <ChipField source="name" />
            </ReferenceField >
            <SelectField source="art" choices={behandlungsart} />
            <NumberField source="menge" />
        </SimpleShowLayout>
    </Show>
);

export default BehandlungShow;