const staerke = [
    { id: 'Sehr Schwach', name: 'Sehr Schwach' },
    { id: 'Schwach', name: 'Schwach' },
    { id: 'Mittel', name: 'Mittel' },
    { id: 'Stark', name: 'Stark' },
    { id: 'Sehr Stark', name: 'Sehr Stark' },
 ];

 const behandlungsart = [
    { id: 'Ameisensäure', name: 'Ameisensäure' },
    { id: 'Nassenheider', name: 'Nassenheider' },
    { id: 'Apilife', name: 'Apilife' },
    { id: 'Oxalsäure', name: 'Oxalsäure' },
    { id: 'Milchsäure', name: 'Milchsäure' },
 ]

 export {staerke, behandlungsart};