import * as React from "react";
import {
    Create,
    ReferenceInput,
    DateInput,
    SelectInput,
    NumberInput,
    required,
    FormTab,
    TabbedForm,
} from 'react-admin';

import {RichTextInput} from 'ra-input-rich-text';
import {staerke} from '../common/beesmart_enums'


const DurchsichtCreate = () => (
    <Create>
        <TabbedForm redirect="list" mutationMode="pessimistic" warnWhenUnsavedChanges >
            <FormTab label="Generell">
                <DateInput source="datum" validate={required()} defaultValue={new Date().toISOString().split('T', 1)[0]}/>
                <ReferenceInput source="stock" reference="stoecke">
                    <SelectInput optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} validate={required()} />
                </ReferenceInput >
                <SelectInput source="staerke" choices={staerke} defaultValue={'Mittel'}/>
                <SelectInput source="schwarmtrieb" choices={staerke} defaultValue={'Mittel'}/>
            </FormTab>
            <FormTab label="Gewicht">
                <NumberInput source="gewicht" />
                <NumberInput source="brutraum" />
                <NumberInput source="honigraum" />
            </FormTab>
            <FormTab label="Details">
                <NumberInput source="befall" />
                <RichTextInput source="notiz" />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default DurchsichtCreate;