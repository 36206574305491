import * as React from "react";
import {
    DateField,
    DateInput,
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {
    required,
    maxLength,
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';

const NoteEdit = () => (
    <Edit mutationMode="pessimistic" >
        <SimpleForm >
            <TextInput source="summary" label="Titel" validate={[required(), maxLength(250)]} />
            <DateField source="creation_date" label="Creation-Date" />
            <DateInput source="due_date" label="Due-Date" />
            <SelectInput source="status" validate={required()} choices={[
                { id: 'open', name: 'Offen' },
                { id: 'in progress', name: 'in Bearbeitung' },
                { id: 'done', name: 'erledigt' },
                { id: 'information', name: 'Information' }]} />
            <RichTextInput source="details" />
        </SimpleForm>
    </Edit>
);

export default NoteEdit;