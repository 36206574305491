import * as React from "react";
import { Card, CardContent, CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import {
    useGetIdentity,
    useGetList,
} from 'react-admin';
import StoeckeInOrt from './StocekInOrt';
import { ChartErnteStoecke } from './ChartErnteStoecke';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em', minWidth: '250px' },
};

const VerticalSpacer = () => <span style={{ height: '1em' }} />;


const Dashboard = () => {
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const { data: orte_data, isLoading: orteLoading } = useGetList(
        "stoecke",
        {},
    );

    if (identityLoading || orteLoading) return null;

    let uniqueOrte: string[] = [];
    if(orte_data)
    {
        orte_data.forEach((elem: any) => {
            if (!uniqueOrte.some((uniq_elem) => (uniq_elem === elem.ort))) {
                uniqueOrte.push(elem.ort);
            }
        });
    }
    return (
        <>
            <Box m={1} >
                <Card>
                    <CardHeader title={"Wilkommen zu beeSmart! - " + identity?.fullName} />
                    <CardContent>
                        Die smarte App für Imker!
                </CardContent>
                </Card>
                <VerticalSpacer />
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
            >
                {uniqueOrte.map((ort: string) => (
                    <Box m={1} >
                        <div style={styles.singleCol}>
                            <StoeckeInOrt inOrt={ort} label={ort} ></StoeckeInOrt>
                        </div>
                    </Box>
                ))}
            </Box>
            <Box m={1} >
                <ChartErnteStoecke />
            </Box>
        </>
    );
};

export default Dashboard;

