import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    ChipField,
    NumberField,
    DateField,
    Labeled,
} from 'react-admin';
import {BeeSmartShowActions} from '../common/beesmart_toolbars';
import {TotalErnteField} from './TotalErnteField';

const ErnteShow = () => (
    <Show
        actions={<BeeSmartShowActions/>}
        >
        <SimpleShowLayout>
            <DateField source="datum" />
            <ReferenceField source="stock" reference="stoecke">
                <ChipField source="name" />
            </ReferenceField >
            <NumberField source="bluete" />
            <NumberField source="sommer" />
            <NumberField source="wald" />
            <NumberField source="linde" />
            <NumberField source="meli" />
            <NumberField source="abschleudern" />
            <Labeled label="Gesamt">
                <TotalErnteField label="Gesamt" />
            </Labeled>
        </SimpleShowLayout>
    </Show>
);

export default ErnteShow;