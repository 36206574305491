import HealingIcon from '@mui/icons-material/Healing';
import BehandlungCreate from './BehandlungCreate';
import BehandlungEdit from './BehandlungEdit';
import BehandlungList from './BehandlungList';
import BehandlungShow from './BehandlungShow';


const behandlung_ressource = {
    list: BehandlungList,
    show: BehandlungShow,
    edit: BehandlungEdit,
    create: BehandlungCreate,
    icon: HealingIcon,
};

export default behandlung_ressource;