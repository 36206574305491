import * as React from "react";
import {
    RaRecord,
    useGetList,
    Labeled,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { startOfYear} from 'date-fns';
import {
    ResponsiveContainer,
    AreaChart,
    LineChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';


function sumColumnSingleField(rows: RaRecord[], field: string): number {
    let total: number = 0;
    rows.forEach(curr => {
        total += curr[field];
    });
    return total;
};

function sumColumnMultipleFields(rows: RaRecord[], fields: string[]): number {
    let total: number = 0;
    rows.forEach(curr => {
        fields.forEach(item => {
            total += curr[item];
        })
    });
    return total;
};


export const SumOfColumnField = ({ ...props }) => {
    const {data: rows, isLoading } = useGetList(
        props.reference,
        {
            filter: {
                "datum,gt": startOfYear(new Date()).toISOString(),
                "stock,eq": props.stock
            },
            sort: { field: 'datum', order: 'DESC' },
        },
    );
    if (isLoading) return null
    let sum = 0;
    if (props.fields) sum = sumColumnMultipleFields(rows as RaRecord[], props.fields)
    else sum = sumColumnSingleField(rows as RaRecord[], props.field)
    return (
        <Labeled label={props.label}>
            <Typography
                variant="body1"
                component="span"
                className="sum_column"
            >
                {new Intl.NumberFormat('de-DE').format(sum)}
            </Typography>
        </Labeled >
    )
};

SumOfColumnField.propTypes = {
    label: PropTypes.string,
    reference: PropTypes.string,
    stock: PropTypes.string,
    field: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
};


const sumFieldForAreaChart = (data: RaRecord[], fields: string[]): { date: Date, value: number }[] => {
    let total: number = 0;
    let res: { date: Date, value: number }[] = [];
    data.forEach(curr => {
        fields.forEach(item => {
            total += curr[item];
        });
        res.push(
            {
                date: curr.datum,
                value: total,
            })
    });
    return res;
};

export const FieldAreaChart = ({ ...props }) => {
    const { data, isLoading } = useGetList(
        props.reference,
        {
            filter: {
                "datum,gt": startOfYear(new Date()).toISOString(),
                "stock,eq": props.stock
            },
            sort: { field: 'datum', order: 'ASC' },
        },
    );
    if (isLoading) return null
    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <AreaChart data={sumFieldForAreaChart(data as RaRecord[], props.fields)}>
                    <XAxis
                        dataKey="date"
                    />
                    <YAxis label={{ value: props.label, angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#795548" fill="#795548" />
                    <CartesianGrid strokeDasharray="3 3" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

FieldAreaChart.propTypes = {
    label: PropTypes.string,
    reference: PropTypes.string,
    stock: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string)
};


const sumFieldForLineChart = (data: RaRecord[], fields: string[]): { date: Date, value: number }[] => {
    let res: { date: Date, value: number }[] = [];
    data.forEach(curr => {
        let total: number = 0;
        fields.forEach(item => {
            total += curr[item];
        });
        if (total !== 0) {
            res.push(
                {
                    date: curr.datum,
                    value: total,
                })
        }
    });
    return res;
};

export const FieldLineChart = ({ ...props }) => {
    const {data, isLoading } = useGetList(
        props.reference,
        {
            filter: {
                "datum,gt": startOfYear(new Date()).toISOString(),
                "stock,eq": props.stock
            },
            sort: { field: 'datum', order: 'ASC' },
        },
    );
    if (isLoading) return null
    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <LineChart data={sumFieldForLineChart(data as RaRecord[], props.fields)}>
                    <XAxis
                        dataKey="date"
                    />
                    <YAxis label={{ value: props.label, angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#795548" fill="#795548" />
                    <CartesianGrid strokeDasharray="3 3" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

FieldLineChart.propTypes = {
    label: PropTypes.string,
    reference: PropTypes.string,
    stock: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string)
};