import * as React from "react";
import {
    Create,
    ReferenceInput,
    DateInput,
    SelectInput,
    NumberInput,
    SimpleForm,
    required
} from 'react-admin';


const FuetterungCreate = () => (
    <Create>
        <SimpleForm redirect="list" warnWhenUnsavedChanges>
            <DateInput source="datum" validate={required()} defaultValue={new Date().toISOString().split('T', 1)[0]}/>
            <ReferenceInput source="stock" reference="stoecke">
                <SelectInput optionText={record=>(record.name+" ("+record.ort+" | "+record.platz+")")} validate={required()}/>
            </ReferenceInput >
            <NumberInput source="menge" />
        </SimpleForm>
    </Create>
);

export default FuetterungCreate;