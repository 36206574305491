import * as React from "react";
import {
    RaRecord,
    useGetList,
} from 'react-admin';
import { startOfYear } from 'date-fns';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import { 
    Card, 
    CardContent, 
    CardHeader, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const aggregateErnteByStock = (stoecke: RaRecord[], ernten: RaRecord[]): any[] => {
    let res: any[] = [];
    stoecke.forEach(stock => {
        let sum: number = 0;
        let data: any[] = [{ date: startOfYear(new Date()).getTime(), value: sum }];  //first entry at start of year with 0
        ernten.forEach(ernte => {
            if (ernte.stock === stock.id) {
                sum += ernte.bluete + ernte.sommer + ernte.wald + ernte.linde + ernte.meli + ernte.abschleudern;
                data.push({ date: new Date(ernte.datum).getTime(), value: sum });
            }
        })
        res.push({ name: stock.name + " (" + stock.ort + " | " + stock.platz + ")", data: data });
    })
    return res;
}

const getTotalErnte = (ernten: RaRecord[]): any => {
    let sum: number = 0;
    let bluete: number = 0;
    let sommer: number = 0;
    let wald: number = 0;
    let linde: number = 0;
    let meli: number = 0;
    let abschleudern:number =0;
    ernten.forEach(ernte => {
        bluete += ernte.bluete;
        sommer += ernte.sommer;
        wald += ernte.wald;
        linde += ernte.linde;
        meli += ernte.meli;
        abschleudern += ernte.abschleudern;
        sum += ernte.bluete + ernte.sommer + ernte.wald + ernte.linde + ernte.meli + ernte.abschleudern;
    })
    return ({"summe": sum, "bluete": bluete, "sommer":sommer, "wald":wald, "linde":linde, "meli":meli, "abschleudern": abschleudern});
}



export const ChartErnteStoecke = () => {
    const { data: stoecke, isLoading: stoecke_loading } = useGetList(
        'stoecke',
        {
            filter: {
                "aktiv": 1,
            },
            sort: { field: 'id', order: 'ASC' },
        },
    );
    const { data: ernten, isLoading: ernte_loading } = useGetList(
        'ernten',
        {
            filter: {
                "datum,gt": startOfYear(new Date()).toISOString(),
            },
            sort: { field: 'datum', order: 'ASC' },
        },
    );
    if (ernte_loading || stoecke_loading) return null
    const res_ernten = getTotalErnte(ernten as RaRecord[])
    return (
        <Card>
            <CardHeader title={"Übersicht Ernten pro Volk"} />
            <CardContent>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Gesamt Ernte laufendens Jahr: {new Intl.NumberFormat('de-DE').format(res_ernten.summe)}
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>Blüte: {new Intl.NumberFormat('de-DE').format(res_ernten.bluete)}</li>
                            <li>Sommer: {new Intl.NumberFormat('de-DE').format(res_ernten.sommer)}</li>
                            <li>Wald: {new Intl.NumberFormat('de-DE').format(res_ernten.wald)}</li>
                            <li>Linde: {new Intl.NumberFormat('de-DE').format(res_ernten.linde)}</li>
                            <li>Meli: {new Intl.NumberFormat('de-DE').format(res_ernten.meli)}</li>
                            <li>Abschleudern: {new Intl.NumberFormat('de-DE').format(res_ernten.abschleudern)}</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <span style={{ height: '1em', display:'inline-block' }} />
                <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart>
                            <XAxis dataKey="date" type="number" tickFormatter={dateFormatter} domain={['auto', 'auto']} allowDuplicatedCategory={false}/>
                            <YAxis dataKey="value" />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip filterNull labelFormatter={dateFormatter} />
                            <Legend />
                            {aggregateErnteByStock(stoecke as RaRecord[], ernten as RaRecord[]).map(s => (
                                <Line dataKey="value" data={s.data} name={s.name} key={s.name} stroke={getRandomColor()} />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}