import * as React from "react";
import {
    Show,
    ReferenceField,
    ChipField,
    NumberField,
    BooleanField,
    RichTextField,
    SelectField,
    DateField,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {staerke} from '../common/beesmart_enums'
import {BeeSmartShowActions} from '../common/beesmart_toolbars';

const DurchsichtShow = () => (
    <Show
        actions={<BeeSmartShowActions/>}>   
        <>
            <TabbedShowLayout>
                <Tab label="Generell">
                <DateField source="datum" />
                <ReferenceField source="stock" reference="stoecke">
                    <ChipField source="name" />
                </ReferenceField >
                    <SelectField source="staerke" choices={staerke} />
                    <SelectField source="schwarmtrieb" choices={staerke} />
                    <BooleanField source="koenigin_gesehen" />
                </Tab>
                <Tab label="Gewicht">
                    <NumberField source="gewicht" />
                    <NumberField source="brutraum" />
                    <NumberField source="honigraum" />
                </Tab>
                <Tab label="Details">
                    <NumberField source="befall" />
                    <RichTextField source="notiz" />
                </Tab>
            </TabbedShowLayout>
        </>
    </Show>
);

export default DurchsichtShow;