import * as React from "react";
import {
    List,
    Datagrid,
    ReferenceField,
    DateField,
    Pagination,
    SimpleList,
    SelectField,
    RichTextField,
    NumberField,
    FunctionField,
    downloadCSV,
    RaRecord,
} from 'react-admin';
import { FilterAside, FilterSmall } from '../common/beesmart_filters';
import {useMediaQuery, Theme } from '@mui/material';
import { staerke } from '../common/beesmart_enums'
import jsonExport from 'jsonexport/dist';

const exporter = (records:RaRecord[], fetchRelatedRecords:any) =>
    fetchRelatedRecords(records, 'stock', 'stoecke').then((posts: { [i: number]: { name: string; ort: string; platz:string }; }) => {
        const data = records.map(record => {
            const { stock, ...recordForExport } = record; // omit stock
            recordForExport.stock = posts[record.stock].name;
            recordForExport.ort = posts[record.stock].ort;
            recordForExport.platz = posts[record.stock].platz;
            return recordForExport;
        });

        jsonExport(data, (error, csv) => {
            if (error) {
                console.error(error);
            }
            downloadCSV(csv, 'durchsichten');
        });
    });

const DurchsichtPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const DurchsichtList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={isSmall ? FilterSmall : undefined}
            aside={<FilterAside />}
            pagination={<DurchsichtPagination />}
            perPage={25}
            sort={{ field: 'datum', order: 'DESC' }}
            exporter={exporter}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record =>
                    (
                        <ReferenceField record={record} label="Volk" source="stock" reference="stoecke" link={false}>
                            <FunctionField
                                label="Stock"
                                render={(record: any) => `${record.name} (${record.ort} | ${record.platz})`}
                            />
                        </ReferenceField>
                    )}
                    secondaryText={record => record.staerke}
                    tertiaryText={record => new Intl.DateTimeFormat("de", { dateStyle: "short" }).format(new Date(record.datum))}
                />
            ) : (
                <Datagrid rowClick="show">
                    <DateField source="datum" />
                    <ReferenceField label="Stock" source="stock" reference="stoecke" link={false}>
                        <FunctionField
                            label="Stock"
                            render={(record: any) => `${record.name} (${record.ort} | ${record.platz})`}
                        />
                    </ReferenceField>
                    <SelectField source="staerke" choices={staerke} />
                    <NumberField source="gewicht" />
                    <RichTextField source="notiz" />
                </Datagrid>
            )}
        </List>
    );
};

export default DurchsichtList;